const trTR = {
    crossmintPayButtonService: {
        CONNECTING: "Bağlanıyor...",
        BUY: "Crossmint ile Satın Al",
        BUY_WITH_ETH: "ETH ile Satın Al",
        BUY_WITH_SOL: "SOL ile Satın Al",
        BUY_WITH_CREDIT_CARD: "Kredi Kartı ile Satın Al",
    },
};
export default trTR;
