const itIT = {
    crossmintPayButtonService: {
        CONNECTING: "Connessione...",
        BUY: "Acquista con Crossmint",
        BUY_WITH_ETH: "Acquista con ETH",
        BUY_WITH_SOL: "Acquista con SOL",
        BUY_WITH_CREDIT_CARD: "Acquista con carta di credito",
    },
};
export default itIT;
