const thTH = {
    crossmintPayButtonService: {
        CONNECTING: "กำลังเชื่อมต่อ...",
        BUY: "ซื้อด้วย Crossmint",
        BUY_WITH_ETH: "ซื้อด้วย ETH",
        BUY_WITH_SOL: "ซื้อด้วย SOL",
        BUY_WITH_CREDIT_CARD: "ซื้อด้วยบัตรเครดิต",
    },
};
export default thTH;
