const Klingon = {
    crossmintPayButtonService: {
        CONNECTING: "yImej...",
        BUY: "Crossmint vItlhutlh",
        BUY_WITH_ETH: "ETH vItlhutlh",
        BUY_WITH_SOL: "SOL vItlhutlh",
        BUY_WITH_CREDIT_CARD: "QelI'qam vItlhutlh",
    },
};
export default Klingon;
